export interface AccountState {
  user: User | null;
}

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_PASSWORD = '@account/update-password';
export const UPDATE_PHOTO = '@account/update-photo';

interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
}

interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
}

interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  payload: AccountState;
}

interface SilentLoginAction {
  type: typeof SILENT_LOGIN;
  payload: AccountState;
}

interface LogoutAction {
  type: typeof LOGOUT;
}

interface UpdateProfileAction {
  type: typeof UPDATE_PROFILE;
  payload: AccountState;
}

interface UpdatePasswordAction {
  type: typeof UPDATE_PASSWORD;
}

interface UpdatePhotoAction {
  type: typeof UPDATE_PHOTO;
  payload: AccountState;
}

export type AccountActionTypes =
  | LoginRequestAction
  | LoginFailureAction
  | LoginSuccessAction
  | SilentLoginAction
  | LogoutAction
  | UpdateProfileAction
  | UpdatePasswordAction
  | UpdatePhotoAction;
