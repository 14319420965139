import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppState } from 'src/store';
import { AccountState } from 'src/store/account/types';

type Props = (allowedRoles?: Role[]) => FC;

const rolesGuard: Props = (allowedRoles) => ({ children }) => {
  const account = useSelector<AppState, AccountState>((state) => state.account);

  if (
    allowedRoles &&
    allowedRoles.length > 0 &&
    account.user &&
    allowedRoles.includes(account.user.groups[0].name)
  ) {
    return <>{children}</>;
  }

  return <Redirect to="/login" />;
};

export default rolesGuard;
