export const getRoleName = (role: Role) => {
  if (role === 'admin') {
    return 'Administrador';
  }
  if (role === 'network_manager') {
    return 'Gestor de Red';
  }
  if (role === 'client') {
    return 'Cliente';
  }
  return '';
};
