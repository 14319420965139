/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import rolesGuard from './components/RolesGuard';
import DashboardLayout from './layouts/DashboardLayout';

type RoutesType = {
  exact?: boolean;
  path?: string;
  guard?: any;
  layout?: any;
  component?: any;
  routes?: RoutesType[];
};

const routesConfig: RoutesType[] = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/dashboard" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/forgot-password',
    component: lazy(() => import('./views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    path: '/p/reset/:token/:uidb64',
    component: lazy(() => import('./views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: lazy(() => import('./views/terms/PrivacyPolicyView'))
  },
  {
    exact: true,
    path: '/terms-of-service',
    component: lazy(() => import('./views/terms/ToSView'))
  },
  {
    exact: true,
    path: '/public/providers/:id/files',
    component: lazy(() => import('./views/public/ProviderFilesView'))
  },
  {
    path: '/dashboard',
    guard: rolesGuard(['admin', 'network_manager', 'client']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('./views/pages/DashboardView'))
      }
    ]
  },
  {
    path: '/companies',
    guard: rolesGuard(['admin', 'network_manager']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/companies',
        component: lazy(
          () => import('./views/pages/CompaniesView/CompaniesList')
        )
      },
      {
        exact: true,
        path: '/companies/:id/:tab',
        component: lazy(
          () => import('./views/pages/CompaniesView/CompaniesDetail')
        )
      }
    ]
  },
  {
    path: '/providers',
    guard: rolesGuard(['admin', 'network_manager', 'client']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/providers',
        component: lazy(
          () => import('./views/pages/ProvidersView/ProvidersList')
        )
      },
      {
        exact: true,
        path: '/providers/:id/:tab',
        component: lazy(
          () => import('./views/pages/ProvidersView/ProvidersDetail')
        )
      }
    ]
  },
  {
    path: '/providers-expiration',
    guard: rolesGuard(['admin', 'network_manager']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/providers-expiration',
        component: lazy(
          () => import('./views/pages/ProvidersView/ProvidersExpirationList')
        )
      }
    ]
  },
  {
    path: '/providers-followup',
    guard: rolesGuard(['admin', 'network_manager']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/providers-followup',
        component: lazy(
          () => import('./views/pages/ProvidersView/ProvidersFollowupList')
        )
      }
    ]
  },
  {
    path: '/providers-reports',
    guard: rolesGuard(['admin', 'network_manager']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/providers-reports',
        component: lazy(
          () => import('./views/pages/ProvidersView/ProvidersReports')
        )
      }
    ]
  },
  {
    path: '/profile',
    guard: rolesGuard(['admin', 'network_manager', 'client']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/profile/:tab',
        component: lazy(() => import('./views/pages/ProfileView'))
      }
    ]
  },
  {
    path: '*',
    guard: GuestGuard,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes: RoutesType[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
