import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppState } from 'src/store';
import { AccountState } from 'src/store/account/types';

const GuestGuard: FC = ({ children }) => {
  const account = useSelector<AppState, AccountState>((state) => state.account);

  if (account.user) {
    return <Redirect to="/dashboard" />;
  }

  return <>{children}</>;
};

export default GuestGuard;
