import JwtDecode from 'jwt-decode';

export const setCardUser = (user: CardLoginResponse) =>
  localStorage.setItem('card-user', JSON.stringify(user));
export const getCardUser = () => localStorage.getItem('card-user');
export const deleteCardUser = () => localStorage.removeItem('card-user');

export const setAuthToken = (token: string) =>
  localStorage.setItem('token', token);
export const setUser = (user: AuthUser) =>
  localStorage.setItem('user', JSON.stringify(user));

export const getAuthToken = () => localStorage.getItem('token');
export const getUser = () => localStorage.getItem('user');

export const deleteAuthToken = () => localStorage.removeItem('token');
export const deleteUser = () => localStorage.removeItem('user');

export const isValidToken = () => {
  const token = getAuthToken();
  if (token === null) {
    return false;
  }
  const decoded = JwtDecode<Token>(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};
