import React from 'react';

function Logo(props) {
  return (
    <img
      alt="iComm Solutions"
      src="/static/logo_white.png"
      style={{ maxHeight: 52, maxWidth: 200, marginLeft: 40 }}
      {...props}
    />
  );
}

export default Logo;
