import React from 'react';

function Logo(props) {
  return (
    <img
      alt="iComm Solutions"
      src="/static/logo.png"
      style={{ maxHeight: 150, maxWidth: '95%' }}
      {...props}
    />
  );
}

export default Logo;
