/* eslint-disable import/prefer-default-export */
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import reduxThunk, { ThunkAction, ThunkMiddleware } from 'redux-thunk';
import { ENABLE_REDUX_LOGGER } from 'src/config';
import accountReducer from './account/reducers';
import { AccountActionTypes } from './account/types';

const loggerMiddleware = createLogger();

const rootReducer = combineReducers({
  account: accountReducer
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppActions = AccountActionTypes;
export type ThunkResult<R> = ThunkAction<R, AppState, undefined, AppActions>;

export function configureStore(preloadedState = {}) {
  const thunkMiddleware = reduxThunk as ThunkMiddleware<AppState, AppActions>;
  let middleware = applyMiddleware(thunkMiddleware);
  if (ENABLE_REDUX_LOGGER) {
    middleware = applyMiddleware(thunkMiddleware, loggerMiddleware);
  }
  const middlewareEnhancer = composeWithDevTools(middleware);
  return createStore(rootReducer, preloadedState, middlewareEnhancer);
}

export default configureStore();
